import TrackingService from 'api/tracking/service'
import {
  DataAT,
  DataEXIT,
  DataFTUE,
  DataMBE,
  DataNAV,
  DataSL,
  DataTAB,
  Event,
  PixelType,
} from 'api/tracking/types'
import { useStore } from 'store'
import { useRouterStore } from 'store/router'
import { useGetConfig } from '../../config/hooks'
import { useGetOffers, useGetTabsOffers } from '../../offer/hooks'

export default function useTracking() {
  const {
    data: config,
    isLoading,
    isError: isConfigError,
  } = useGetConfig({ enabled: false })

  const isTabs = config?.isTabs

  const isTabOneEmpty = (data) => {
    if (
      data.pages[0].tabOneOffers.length <= 0 &&
      data.pages[0].tabTwoOffers.length > 1
    ) {
      return 'tabTwoOffers'
    }
    return 'tabOneOffers'
  }

  let getOffersHook
  if (!isLoading && !isConfigError) {
    // Dynamically set the hook based on the value of `!isTabs`
    getOffersHook = !isTabs
      ? useGetOffers({ enabled: false })
      : useGetTabsOffers({ enabled: false })
  }
  const { data } = getOffersHook || {}
  const { requestID } = useStore()
  const attributes = data?.pages[0].dataAttributes
  const pagination = data?.pages[data?.pages.length - 1].pagination
  const offers = data?.pages[0][isTabs ? isTabOneEmpty(data) : 'offers']
  const currencySale = data?.pages[0].currencySale
  const { query } = useRouterStore()

  function injectPixel(type: 'AT', data: DataAT): void
  function injectPixel(type: 'OFW'): void
  function injectPixel(type: 'MBE', data: DataMBE): void
  function injectPixel(type: 'SL', data: DataSL): void
  function injectPixel(type: 'NAV', data: DataNAV): void
  function injectPixel(type: 'FTUE', data: DataFTUE): void
  function injectPixel(type: 'EXIT', data: DataEXIT): void
  function injectPixel(type: 'TAB', data: DataTAB): void

  function injectPixel(type: PixelType, data = undefined) {
    if (!attributes || !pagination || !offers || !currencySale) {
      return
    }

    const queryData = {
      query,
      config,
      attributes,
      pagination,
      currencySale,
      offers,
      requestID,
    }
    const src = getPixelUrl(type, { ...queryData, ...data })
    createImage(src)
  }

  function fireEvent(event: Event, data?: any) {
    const src = getEventUrl(event, data)
    createImage(src)
  }

  return { injectPixel, fireEvent }
}

function createImage(src) {
  const img = new Image()
  img.setAttribute('src', src)
}

function getPixelUrl(type: PixelType, data) {
  switch (type) {
    case 'AT':
      return TrackingService.at(data)
    case 'OFW':
      return TrackingService.ofw(data)
    case 'MBE':
      return TrackingService.mbe(data)
    case 'SL':
      return TrackingService.sl(data)
    case 'NAV':
      return TrackingService.nav(data)
    case 'FTUE':
      return TrackingService.ftue(data)
    case 'EXIT':
      return TrackingService.exit(data)
    case 'TAB':
      return TrackingService.tab(data)
    default:
      throw new Error(`Invalid pixel type: ${type}`)
  }
}

function getEventUrl(event: Event, data) {
  return TrackingService.event(event, data)
}

export const nl = {
  translations: {
    action_complete_q_action: `Voltooi een snelle actie`,
    action_complete_survey: `Vul een snelle enquête in`,
    action_social_media: `Sociale media betrokkenheid`,
    action_simple_registration: `Doe een eenvoudige registratie`,
    action_discover_new: `Ontdek een nieuwe dienst/product`,
    action_mobile_sub: `Krijg een speciaal mobiel abonnement`,
    action_purchase: `Een aankoop doen.`,
    action_new_service: `Probeer een nieuwe dienst/product`,
    action_complete_action: `Voltooi een handeling`,
    action_quick_quiz: `Beantwoord een snelle quiz`,
    action_donation: `Maak een donatie`,
    tags_free: `Gratis`,
    tags_limited_offer: `Beperkt aanbod`,
    tags_multi_reward: `Meervoudige beloning`,
    tags_top_offer: `Top aanbieding!`,
    tags_new_users: `Alleen nieuwe gebruikers`,
    up_to: `Tot +{{ currencyValue }} {{ currency }}`,
    menu_faq: `Veelgestelde vragen`,
    menu_offers: `Aanbiedingen`,
    menu_status: `Status`,
    status_pending: `In afwachting`,
    status_reported: `Gerapporteerd`,
    status_expired: `Vervallen`,
    status_rejected: `Afgewezen`,
    status_completed: `Voltooide taak`,
    dtUserId: `Uw Digital Turbine Gebruikers-ID is <1>{{ digitalTurbineId }}</1>`,
    complete_text: `Voltooien`,
    check_inbox: `Deze aanbieding is gerapporteerd. Controleer uw e-mail voor het antwoord.`,
    contacted: `Bedankt voor het contact opnemen met onze klantenservice. We komen zo snel mogelijk bij u terug.`,
    description_report: `<0>Bedankt voor het contact opnemen met het ondersteuningsteam voor gebruikers. Er zijn twee veelvoorkomende redenen voor problemen met het ontvangen van {{ currencyText }}</0><1>1. Niet voldoen aan de eisen van de aanbieding</1><2>Apps moeten worden gedownload EN gestart om de aanbieding te voltooien.</2><3>Alle gegevens moeten correct en waarheidsgetrouw worden ingevoerd.</3><4>Vaak moet u uw e-mailadres valideren via een link die naar uw e-mail is gestuurd.</4><5>Veel advertentiepartners staan slechts één transactie per persoon of huishouden toe.</5><6>2. Technische redenen</6><7>U had geen werkende gegevensverbinding toen u een gepromote app startte.</7><8>U had de gepromote app al geïnstalleerd voordat u de aanbieding startte.</8>`,
    problem_report: `Probleemrapport voor {{ appName }}`,
    problem_type1: `Geen credits ontvangen`,
    problem_type2: `Fout tijdens aanbieding`,
    problem_type3: `Overig`,
    email_confirmation: `Bevestig uw e-mailadres`,
    full_name: `Volledige naam`,
    message: `Bericht`,
    submit_text: `Versturen`,
    onboarding_title_1: `Voltooi de aanbieding & ontvang beloningen`,
    onboarding_title_2: `Controleer de aanbiedingsstatus`,
    onboarding_title_3: `Ontbrekende beloningen?`,
    offer_instructions: `Aanbiedingsinstructies`,
    offer_details: `*Het kan enkele dagen duren voordat beloningen in uw account verschijnen`,
    next_text: `Volgende`,
    done_text: `Klaar`,
    onboarding_page2_text1: `Open het menu linksboven`,
    onboarding_page2_text2: `Tik op "Status" om al uw aanbiedingen te bekijken`,
    onboarding_page2_text3: `Zoek uw aanbieding en tik om details te openen`,
    onboarding_page3_text1: `Zoek de aanbieding en tik op "Probleem rapporteren"`,
    onboarding_page3_text2: `Vul het formulier in en dien uw probleem in`,
    onboarding_page3_text3: `Ons ondersteuningsteam zal contact met u opnemen!`,
    reengagement_earn_up: `Verdien tot <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `U heeft nog aanbiedingen die lopen! <1></1> Het is nog niet te laat om ze af te ronden`,
    reengagement_status_text: `Blijf spelen`,
    reengagement_expires1: `Verloopt in {{ days }} dagen`,
    reengagement_expires2: `Verloopt in {{ hours }} uren`,
    reengagement_button_text: `BEKIJK ALLE AANBIEDINGEN`,
    modal_reward: `Totale beloningen`,
    modal_task: `Totaal aantal taken`,
    modal_time: `Dagen om te voltooien`,
    modal_tasks_completed: `Taken voltooid`,
    modal_remaining_days: `Dagen over`,
    modal_tasks_text: `Taken`,
    modal_instructions_text: `Instructies`,
    complete_offer: `Voltooi aanbieding`,
    report_button_text: `Heeft u problemen? Klik hier`,
    report_timestamp: `U kunt deze aanbieding over {{ reportTime }} melden`,
    menu_user_support_guide: `Gebruikersondersteuningsgids`,
    banner_support: `Ondersteuning`,
    mr_text_earned: `Verdiend`,
    mr_text_total: `Totaal`,
    earn_up_to: `Verdien tot {{ currencyValue }} {{ currency }}`,
    earn_text: `Verdien {{ currencyValue }} {{ currency }}`,
    up_to_text: `Tot`,
    tab_1_label: `Hoogbetaalde aanbiedingen`,
    tab_2_label: `Gemakkelijke aanbiedingen`,
  },
}

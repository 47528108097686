export const ja = {
  translations: {
    action_complete_q_action: `簡単なアクション`,
    action_complete_survey: `アンケートに回答`,
    action_social_media: `SNSを通して参加`,
    action_simple_registration: `簡単なご登録を行う`,
    action_discover_new: `新しいサービスを発見`,
    action_mobile_sub: `携帯の新規契約を行う`,
    action_purchase: `購入する`,
    action_new_service: `新しいサービスを試す`,
    action_complete_action: `アクションを完了させる`,
    action_quick_quiz: `クイズを回答`,
    action_donation: `寄付`,
    tags_free: `無料`,
    tags_limited_offer: `限定オファー`,
    tags_multi_reward: `マルチリワード`,
    tags_top_offer: `マルチリワード`,
    tags_new_users: `新規ユーザーのみ`,
    up_to: `上限 {{ currency }}`,
    menu_faq: `FAQ`,
    menu_offers: `オファー`,
    menu_status: `ステータス`,
    status_pending: `待機中`,
    status_reported: `報告済み`,
    status_expired: `期限切れ`,
    status_rejected: `承認が下りませんでした`,
    status_completed: `完了した作業`,
    dtUserId: `あなたのDigital Turbine ユーザーID: <1>{{ digitalTurbineId }}</1>`,
    complete_text: `完了`,
    check_inbox: `本オファーは報告されています。メールで返信内容をご確認ください。`,
    contacted: `お客様センターにお問い合わせいただきありがとうございます。現在対応しておりますので、もうしばらくお待ちください。`,
    description_report: `<0>ユーザーサポートチームにお問い合わせいただきありがとうございます。{{ currencyText }}を受け取る際の問題には、一般的に2つの理由があります。</0><1>1. オファーの要件が満たされていません</1><2>オファーを完了するには、アプリをダウンロードして起動する必要があります。</2><3>すべてのデータは正確かつ真実に入力する必要があります。</3><4>よくあるのは、メールに送られたリンクを通じてメールアドレスを検証する必要があります。</4><5>多くの広告パートナーは個人または世帯ごとに1回の取引しか許可していません。</5><6>2. 技術的な理由</6><7>プロモーションされたアプリを起動する際に動作するデータ接続がなかった可能性があります。</7><8>オファーを開始する前に、既にプロモーションされたアプリがインストールされていた可能性があります。</8>`,
    problem_report: `{{ appName }}に関する報告`,
    problem_type1: `報酬を受け取れませんでした`,
    problem_type2: `実行中エラーが発生`,
    problem_type3: `その他`,
    email_confirmation: `メールアドレスをご確認ください`,
    full_name: `氏名`,
    message: `メッセージ`,
    submit_text: `送信`,
    onboarding_title_1: `オファーを完了して報酬を受け取る`,
    onboarding_title_2: `オファー状況の確認`,
    onboarding_title_3: `報酬が不足している場合`,
    offer_instructions: `オファーの説明`,
    offer_details: `*特典がアカウントに反映されるまで数日かかる場合があります。`,
    next_text: `次へ`,
    done_text: `完了`,
    onboarding_page2_text1: `左上のメニューを開いてください`,
    onboarding_page2_text2: `すべてのオファーリストの確認は「ステータス」をタップ`,
    onboarding_page2_text3: `オファーを見つけ、タップして詳細を開く`,
    onboarding_page3_text1: `オファーを見つけ、「問題を報告する」をタップ`,
    onboarding_page3_text2: `フォームに記入し、問題を報告`,
    onboarding_page3_text3: `弊社サポートチームより折り返しご連絡いたします！`,
    reengagement_earn_up: `<1> {{ currencyName }}+{{ currencyValue }}まで</1>獲得可能`,
    reengagement_description: `まだ進行中のオファーがあります<1></1> まだ獲得まで間に合います！`,
    reengagement_status_text: `プレイを続ける`,
    reengagement_expires1: `有効期限{{ days }}日`,
    reengagement_expires2: `有効期限{{ hours }}時間`,
    reengagement_button_text: `すべてのオファーを見る`,
    modal_reward: `総合報酬`,
    modal_task: `アクションの合計`,
    modal_time: `完了までの日数`,
    modal_tasks_completed: `完了したアクション`,
    modal_remaining_days: `残り日数`,
    modal_tasks_text: `タスク`,
    modal_instructions_text: `手順`,
    complete_offer: `オファー完了`,
    report_button_text: `問題が発生していますか？こちらをクリックしてください`,
    report_timestamp: `このオファーを {{ reportTime }} 以内に報告できます`,
    menu_user_support_guide: `ユーザーサポートガイド`,
    banner_support: `サポート`,
    mr_text_earned: `獲得済み`,
    mr_text_total: `合計`,
    earn_up_to: `最大で {{ currencyValue }} {{ currency }} 獲得可能`,
    earn_text: `{{ currencyValue }} {{ currency }} を獲得`,
    up_to_text: `上限`,
    tab_1_label: `大量リワード`,
    tab_2_label: `簡単リワード`,
  },
}

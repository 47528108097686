import { ConfigDto } from 'api/config/types'

export const config: ConfigDto = {
  active_experiments: [
    {
      id: 1,
      group: 'b',
      name: 'new_banner',
    },
  ],
  colors: {
    button_color: '#DA1A42',
    button_text_color: '#FFFFFF',
    tag_color: '#251546',
  },
  custom_banner_mobile:
    'https://ofw-assets-stg.s3.eu-west-1.amazonaws.com/opd/placement_mobile_banner/f7ef137f-7b5a-471d-811e-e46a36fb74e4.jpeg',
  custom_banner_tablet:
    '//aws-production-eu-west-1-ofw-client-assets.s3-eu-west-1.amazonaws.com/ofw-assets/1a874cdfb993dbc41734e712e18e4c5e72ca45196d84c73272f449d237502791.png',
  currency_icon: 'http://localhost:3000/images/banana-icon.png',
  currency_name: 'Banana',
  virtual_currency_sale_enabled: false,
  virtual_currency_sale_reward_multiplier: 2.4,
  main_color: '#3081cc',
  vcs_time_left: null,
  show_onboarding: true,
  tabs_enabled: false,
}

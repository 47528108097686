export const tr = {
  translations: {
    action_complete_q_action: `Eylemi hızlıca tamamla `,
    action_complete_survey: `Anketi hızlıca tamala`,
    action_social_media: `Sosyal Medya Katılımı`,
    action_simple_registration: `Basit bir kayıt yap`,
    action_discover_new: `Yeni bir hizmet/ürün keşfet`,
    action_mobile_sub: `Özel bir mobil abonelik al`,
    action_purchase: `Satın alma yap`,
    action_new_service: `Yeni bir hizmet/ürünü dene`,
    action_complete_action: `Bir eylemi tamamla`,
    action_quick_quiz: `Hızlı bir bilgi yarışmasını yanıtla`,
    action_donation: `Bağış yap`,
    tags_free: `Ücretsiz`,
    tags_limited_offer: `Sınırlı teklif`,
    tags_multi_reward: `Çoklu Ödül`,
    tags_top_offer: `En İyi Teklif!`,
    tags_new_users: `Sadece Yeni Kullanıcılar`,
    up_to: `{{ currency }}'ye kadar`,
    menu_faq: `SSS`,
    menu_offers: `Teklifler`,
    menu_status: `Durum`,
    status_pending: `Beklemede`,
    status_reported: `Bildirilen`,
    status_expired: `Süresi Dolmuş`,
    status_rejected: `Reddedilen`,
    status_completed: `Tamamlanan görev`,
    dtUserId: `Digital Turbine Kullanıcı Kimliğiniz <1>{{ digitalTurbineId }}</1>`,
    complete_text: `Tamamla`,
    check_inbox: `Bu teklif bildirildi. Lütfen yanıtınız için e-postanızı kontrol edin.`,
    contacted: `Müşteri hizmetlerimize başvurduğunuz için teşekkür ederiz. En kısa sürede size döneceğiz.`,
    description_report: `<0>Kullanıcı destek ekibine başvurduğunuz için teşekkür ederiz. {{ currencyText }} alımıyla ilgili sorunlar için genellikle iki yaygın neden bulunmaktadır.</0><1>1. Teklif gereksinimleri karşılanmadı</1><2>Teklifi tamamlamak için uygulamaların indirilip VE başlatılması gerekmektedir.</2><3>Tüm verilerin doğru ve dürüst bir şekilde girilmesi gerekmektedir.</3><4>Çoğu zaman, e-postanıza gönderilen bir bağlantı aracılığıyla e-posta adresinizi doğrulamanız gerekebilir.</4><5>Birçok reklam ortağı, kişi veya hane başına sadece bir işlem izin verir.</5><6>2. Teknik nedenler</6><7>Bir promosyonlu uygulamayı başlatırken çalışan bir veri bağlantınız yoktu.</7><8>Teklifi başlatmadan önce zaten promosyonlu uygulama yüklüydü.</8>`,
    problem_report: `{{ appName }} için problem raporu`,
    problem_type1: `Kredi alınmadı`,
    problem_type2: `Teklif sırasında hata`,
    problem_type3: `Diğer`,
    email_confirmation: `E-postanızı onaylayın`,
    full_name: `Tam ad`,
    message: `Mesaj`,
    submit_text: `Gönder`,
    onboarding_title_1: `Teklifi tamamla ve ödüller al`,
    onboarding_title_2: `Teklif Durumunu Kontrol Et`,
    onboarding_title_3: `Eksik Ödüller?`,
    offer_instructions: `Teklif Talimatları`,
    offer_details: `*Ödüllerin hesabınızda görünmesi birkaç gün sürebilir`,
    next_text: `Sonraki`,
    done_text: `Tamamlandı`,
    onboarding_page2_text1: `Sol üst köşede bulunan menüyü aç`,
    onboarding_page2_text2: `Tüm tekliflerini görüntülemek için "Durum"u dokun`,
    onboarding_page2_text3: `Teklifini bul ve dokunarak detayları aç`,
    onboarding_page3_text1: `Teklifi bul ve "Sorun Bildir"e dokun`,
    onboarding_page3_text2: `Formu doldur ve sorununu gönder`,
    onboarding_page3_text3: `Destek Ekibimiz size geri dönecek!`,
    reengagement_earn_up: `<1>+{{ currencyName }}</1> {{ currencyValue }}'na kadar kazanın`,
    reengagement_description: `Hala devam eden tekliflerin var! <1></1> Onları tamamlamak için hâlâ geç değil`,
    reengagement_status_text: `Oynamaya devam et`,
    reengagement_expires1: `{{ days }} gün içinde sona eriyor`,
    reengagement_expires2: `{{ hours }} saat içinde sona eriyor`,
    reengagement_button_text: `TÜM TEKLİFLERİ GÖRÜNTÜLE`,
    modal_reward: `Toplam Ödüller`,
    modal_task: `Toplam Görevler`,
    modal_time: `Tamamlanması Gereken Günler`,
    modal_tasks_completed: `Tamamlanan Görevler`,
    modal_remaining_days: `Kalan Günler`,
    modal_tasks_text: `Görevler`,
    modal_instructions_text: `Talimatlar`,
    complete_offer: `Teklifi Tamamla`,
    report_button_text: `Sorun mu yaşıyorsunuz? Buraya tıklayın`,
    report_timestamp: `Bu teklifi {{ reportTime }} içinde bildirebileceksiniz`,
    menu_user_support_guide: `Kullanıcı Destek Rehberi`,
    banner_support: `Destek`,
    mr_text_earned: `Kazanılan`,
    mr_text_total: `Toplam`,
    earn_up_to: `En fazla kazan {{ currencyValue }} {{ currency }}`,
    earn_text: `{{ currencyValue }} {{ currency }} Kazan`,
    up_to_text: `ye kadar`,
    tab_1_label: `Yüksek kazançlı Teklifler`,
    tab_2_label: `Kolay Teklifler`,
  },
}

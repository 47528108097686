export const fr = {
  translations: {
    action_complete_q_action: `Complétez une action rapide`,
    action_complete_survey: `Complétez une enquête rapide`,
    action_social_media: `Engagement sur les réseaux sociaux`,
    action_simple_registration: `Effectuez une simple inscription`,
    action_discover_new: `Découvrez un nouveau service/produit`,
    action_mobile_sub: `Obtenez un abonnement sur mobile`,
    action_purchase: `Effectuez un achat`,
    action_new_service: `Essayez un nouveau service/produit`,
    action_complete_action: `Complétez une action`,
    action_quick_quiz: `Répondez à un quiz rapide`,
    action_donation: `Faîtes un don`,
    tags_free: `Gratuit`,
    tags_limited_offer: `Offre limitée`,
    tags_multi_reward: `Multi-récompense`,
    tags_top_offer: `Meilleure offre !`,
    tags_new_users: `Nouveaux utilisateurs uniquement`,
    up_to: `Jusqu'à +{{ currencyValue }} {{ currency }}`,
    menu_faq: `FAQ`,
    menu_offers: `Offres`,
    menu_status: `Statut`,
    status_pending: `En attente`,
    status_reported: `Signalé`,
    status_expired: `Expiré`,
    status_rejected: `Rejeté`,
    status_completed: `Tâche terminée`,
    dtUserId: `Votre identifiant utilisateur Digital Turbine est <1>{{ digitalTurbineId }}</1>`,
    complete_text: `Complet`,
    check_inbox: `Cette offre a été signalée. Veuillez vérifier notre reponse dans votre boite e-mail.`,
    contacted: `Merci d'avoir contacté notre service client. Nous reviendrons vers vous dès que possible`,
    description_report: `<0>Merci de contacter l'équipe de support utilisateur. Il existe deux raisons courantes pour les problèmes liés à la réception de {{ currencyText }}</0><1>1. Non-respect des exigences de l'offre</1><2>Les applications doivent être téléchargées ET lancées pour compléter l'offre.</2><3>Toutes les données doivent être saisies correctement et honnêtement.</3><4>Souvent, vous devez valider votre adresse e-mail via un lien envoyé à votre adresse e-mail.</4><5>De nombreux partenaires publicitaires n'autorisent qu'une seule transaction par personne ou par foyer.</5><6>2. Raisons techniques</6><7>Vous n'aviez pas de connexion de données fonctionnelle lorsque vous avez lancé une application promue.</7><8>Vous aviez déjà installé l'application promue avant de commencer l'offre.</8>`,
    problem_report: `Rapport de problème pour {{ appName }}`,
    problem_type1: `Aucun crédit reçu`,
    problem_type2: `Erreur pendant l'offre`,
    problem_type3: `Autre`,
    email_confirmation: `Confirmez votre adresse e-mail`,
    full_name: `Nom complet`,
    message: `Message`,
    submit_text: `Envoyer`,
    onboarding_title_1: `Complétez l'offre et recevez des récompenses`,
    onboarding_title_2: `Vérifiez l'état de l'offre`,
    onboarding_title_3: `Récompenses manquantes ?`,
    offer_instructions: `Instructions de l'offre`,
    offer_details: `*Certaines récompenses peuvent prendre quelques jours pour apparaître sur votre compte`,
    next_text: `Suivant`,
    done_text: `Terminé`,
    onboarding_page2_text1: `Ouvrez le menu situé dans le coin supérieur gauche`,
    onboarding_page2_text2: `Appuyez sur "Statut" pour voir toutes vos offres`,
    onboarding_page2_text3: `Trouvez votre offre et appuyez pour ouvrir les détails`,
    onboarding_page3_text1: `Trouvez l'offre et appuyez sur "Signaler un problème"`,
    onboarding_page3_text2: `Remplissez le formulaire et soumettez votre problème`,
    onboarding_page3_text3: `Notre équipe de support reviendra vers vous !`,
    reengagement_earn_up: `Gagnez jusqu'à <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `Vous avez encore des offres en cours ! <1></1> Il n'est pas trop tard pour les compléter`,
    reengagement_status_text: `Continuez à jouer`,
    reengagement_expires1: `Expire dans {{ days }} jours`,
    reengagement_expires2: `Expire dans {{ hours }} heures`,
    reengagement_button_text: `VOIR TOUTES LES OFFRES`,
    modal_reward: `Récompenses totales`,
    modal_task: `Tâches totales`,
    modal_time: `Jours pour terminer`,
    modal_tasks_completed: `Tâches terminées`,
    modal_remaining_days: `Jours restants`,
    modal_tasks_text: `Tâches`,
    modal_instructions_text: `Instructions`,
    complete_offer: `Offre complète`,
    report_button_text: `Vous rencontrez des problèmes ? Cliquez ici`,
    report_timestamp: `Vous pourrez signaler cette offre dans les {{ reportTime }}`,
    menu_user_support_guide: `Guide assistance à l'utilisateur`,
    banner_support: `Assistance`,
    mr_text_earned: `Gagné`,
    mr_text_total: `Total`,
    earn_up_to: `Gagnez jusqu'à {{ currencyValue }} {{ currency }}`,
    earn_text: `Gagnez {{ currencyValue }} {{ currency }}`,
    up_to_text: `Jusqu'à`,
    tab_1_label: `Offres les plus généreuses`,
    tab_2_label: `Offres faciles`,
  },
}

export const en = {
  translations: {
    action_complete_q_action: `Complete a quick action`,
    action_complete_survey: `Complete a quick survey`,
    action_social_media: `Social Media Engagement`,
    action_simple_registration: `Do a simple registration`,
    action_discover_new: `Discover a new service/product`,
    action_mobile_sub: `Get a special mobile subscription`,
    action_purchase: `Make a purchase`,
    action_new_service: `Try a new service/product`,
    action_complete_action: `Complete an action`,
    action_quick_quiz: `Answer a quick quiz`,
    action_donation: `Make a donation`,
    tags_free: `Free`,
    tags_limited_offer: `Limited offer`,
    tags_multi_reward: `Multi Reward`,
    tags_top_offer: `Top Offer!`,
    tags_new_users: `New Users Only`,
    up_to: `Up to +{{ currencyValue }} {{ currency }}`,
    menu_faq: `FAQ`,
    menu_offers: `Offers`,
    menu_status: `Status`,
    status_pending: `Pending`,
    status_reported: `Reported`,
    status_expired: `Expired`,
    status_rejected: `Rejected`,
    status_completed: `Completed task`,
    dtUserId: `Your Digital Turbine User ID is <1>{{ digitalTurbineId }}</1>`,
    complete_text: `Complete`,
    check_inbox: `This offer has been reported. Please check your email for the reply.`,
    contacted: `Thank you for contacting our customer care. We will get back to you as soon as possible`,
    description_report: `<0>Thank you for contacting user support team. There are two common reasons for problems with receiving {{ currencyText }}</0><1>1. Offer requirements not met</1><2>Apps have to be downloaded AND launched to complete the offer.</2><3>All data needs to be entered correctly and truthfully.</3><4>Oftentimes you need to validate your email address through a link sent to your email.</4><5>Many advertising partners allow only one transaction per person or household.</5><6>2. Technical reasons</6><7>You did not have a working data connection when you started a promoted app.</7><8>You already had the promoted app installed before you started the offer.</8>`,
    problem_report: `Problem report for {{ appName }}`,
    problem_type1: `No credits received`,
    problem_type2: `Error during offer`,
    problem_type3: `Other`,
    email_confirmation: `Confirm your Email`,
    full_name: `Full name`,
    message: `Message`,
    submit_text: `Submit`,
    onboarding_title_1: `Complete the offer & receive awards`,
    onboarding_title_2: `Check Offer Status`,
    onboarding_title_3: `Missing Rewards?`,
    offer_instructions: `Offer Instructions`,
    offer_details: `*It may take several days for rewards to appear in your account`,
    next_text: `Next`,
    done_text: `Done`,
    onboarding_page2_text1: `Open the menu located in the top left corner`,
    onboarding_page2_text2: `Tap "Status" to view all your offers`,
    onboarding_page2_text3: `Find your offer and tap to open details`,
    onboarding_page3_text1: `Find the offer and tap "Report Issue"`,
    onboarding_page3_text2: `Fill out the form and submit your issue`,
    onboarding_page3_text3: `Our Support Team will get back to you!`,
    reengagement_earn_up: `Earn up to <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `You still have offers that are in progress! <1></1> It is not too late to complete them`,
    reengagement_status_text: `Continue playing`,
    reengagement_expires1: `Expires in {{ days }} days`,
    reengagement_expires2: `Expires in {{ hours }} hours`,
    reengagement_button_text: `SEE ALL OFFERS`,
    modal_reward: `Total Rewards`,
    modal_task: `Total Tasks`,
    modal_time: `Days to Complete`,
    modal_tasks_completed: `Tasks Completed`,
    modal_remaining_days: `Days Left`,
    modal_tasks_text: `Tasks`,
    modal_instructions_text: `Instructions`,
    complete_offer: `Complete Offer`,
    report_button_text: `Having issues? Click here`,
    report_timestamp: `You'll be able to report this offer in {{ reportTime }}`,
    menu_user_support_guide: `User Supporting Guide`,
    banner_support: `Support`,
    mr_text_earned: `Earned`,
    mr_text_total: `Total`,
    earn_up_to: `Earn up to {{ currencyValue }} {{ currency }}`,
    earn_text: `Earn {{ currencyValue }} {{ currency }}`,
    up_to_text: `Up to`,
    tab_1_label: `High paying Offers`,
    tab_2_label: `Easy Offers`,
  },
}

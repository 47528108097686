import { UrlQuery } from 'types/url'
import { createQueryString } from 'utils/createQueryString'
import { getDeviceParameters } from 'utils/getDeviceParameters'
import { api } from '..'
import { configFromDto } from './dto'
import { Config, ConfigDto, VariantName } from './types'

export default class ConfigService {
  static async get(urlQuery: UrlQuery): Promise<Config> {
    const url = createUrl(urlQuery)
    const response = await api.get<ConfigDto>(url)

    const { uid } = urlQuery
    if (uid.includes('expgroup:')) {
      const variant = uid.slice(-1) as VariantName
      response.data.active_experiments = response.data.active_experiments.map(
        (exp) => {
          exp.group = variant
          return exp
        }
      )
    }

    return configFromDto(response.data)
  }

  static async getPreview(urlQuery: UrlQuery): Promise<Config> {
    const newUrlQuery: any = { ...urlQuery }
    if (urlQuery.custom_banner_mobile === 'null')
      newUrlQuery.custom_banner_mobile = null

    if (urlQuery.custom_banner_tablet === 'null')
      newUrlQuery.custom_banner_tablet = null

    if (urlQuery.currency_icon === 'null') newUrlQuery.currency_icon = null

    newUrlQuery.tabs_enabled = urlQuery.tabs_enabled === 'true'

    newUrlQuery.virtual_currency_sale_enabled =
      urlQuery.virtual_currency_sale_enabled === 'true'

    newUrlQuery.virtual_currency_sale_reward_multiplier = parseFloat(
      urlQuery.virtual_currency_sale_reward_multiplier
    )

    newUrlQuery.colors = {
      button_color: urlQuery.button_color,
      tag_color: urlQuery.tag_color,
      button_text_color: urlQuery.button_text_color,
    }

    newUrlQuery.vcs_time_left = 10000

    return configFromDto(newUrlQuery)
  }
}

function createUrl(urlQuery: UrlQuery): string {
  const BASE_URL = `/client_config`
  const { deviceId, deviceType } = getDeviceParameters(urlQuery)

  const query = {
    appid: urlQuery.appid,
    uid: urlQuery.uid,
    client: urlQuery.client,
    device_id: deviceId,
    device_type: deviceType,
    placement_id: urlQuery.placement_id,
    one_dtid: urlQuery.one_dtid,
    nc: 0,
  }

  const encodedUrlQuery = createQueryString(query)
  return `${BASE_URL}?${encodedUrlQuery}`
}

export const pl = {
  translations: {
    action_complete_q_action: `Wykonaj szybką akcję`,
    action_complete_survey: `Wykonaj szybką ankietę`,
    action_social_media: `Zaangażowanie w media społecznościowe`,
    action_simple_registration: `Dokonaj prostego zarejestrowania`,
    action_discover_new: `Odkryj nową usługę/produkt`,
    action_mobile_sub: `Zdobądź specjalną subskrypcję mobilną`,
    action_purchase: `Zakup`,
    action_new_service: `Wypróbuj nową usługę/produkt`,
    action_complete_action: `Wykonaj akcję`,
    action_quick_quiz: `Odpowiedz na szybki quiz`,
    action_donation: `Dokonaj darowizny`,
    tags_free: `Darmowe`,
    tags_limited_offer: `Oferta ograniczona`,
    tags_multi_reward: `Multi Reward`,
    tags_top_offer: `Topowa oferta!`,
    tags_new_users: `Tylko nowi użytkownicy`,
    up_to: `Do +{{ currencyValue }} {{ currency }}`,
    menu_faq: `FAQ`,
    menu_offers: `Oferty`,
    menu_status: `Status`,
    status_pending: `Oczekuje`,
    status_reported: `Zgłoszone`,
    status_expired: `Wygasłe`,
    status_rejected: `Odrzucone`,
    status_completed: `Zakończono zadanie`,
    dtUserId: `Twój identyfikator użytkownika Digital Turbine to <1>{{ digitalTurbineId }}</1>`,
    complete_text: `Zakończ`,
    check_inbox: `Ta oferta została zgłoszona. Proszę sprawdzić swój e-mail w celu uzyskania odpowiedzi.`,
    contacted: `Dziękujemy za skontaktowanie się z naszym centrum obsługi klienta. Odpowiemy tak szybko, jak to możliwe.`,
    description_report: `Dziękujemy za skontaktowanie się z zespołem wsparcia użytkownika. Istnieją dwa powszechne powody problemów z otrzymywaniem {{ currencyText }}</0><1>1. Nie spełnienie wymagań oferty</1><2>Aplikacje muszą być pobrane I uruchomione, aby zakończyć ofertę.</2><3>Wszystkie dane muszą być wprowadzone poprawnie i zgodnie z prawdą.</3><4>Często konieczne jest potwierdzenie swojego adresu e-mail poprzez link wysłany na Twój adres e-mailowy.</4><5>Wielu partnerów reklamowych pozwala tylko na jedną transakcję na osobę lub gospodarstwo domowe.</5><6>2. Przyczyny techniczne</6><7>Nie było działającego połączenia danych, gdy uruchamiałeś promowaną aplikację.</7><8>Posiadałeś już zainstalowaną promowaną aplikację przed rozpoczęciem oferty.</8>`,
    problem_report: `Raport problemu dla {{ appName }}`,
    problem_type1: `Brak otrzymanych kredytów`,
    problem_type2: `Błąd podczas oferty`,
    problem_type3: `Inny`,
    email_confirmation: `Potwierdź swój adres e-mail`,
    full_name: `Pełne imię`,
    message: `Wiadomość`,
    submit_text: `Wyślij`,
    onboarding_title_1: `Zakończ ofertę i odbierz nagrody`,
    onboarding_title_2: `Sprawdź status oferty`,
    onboarding_title_3: `Brakujące nagrody?`,
    offer_instructions: `Instrukcje oferty`,
    offer_details: `*Niektóre nagrody mogą potrwać kilka dni, zanim pojawią się na koncie`,
    next_text: `Dalej`,
    done_text: `Gotowe`,
    onboarding_page2_text1: `Otwórz menu znajdujące się w lewym górnym rogu`,
    onboarding_page2_text2: `Stuknij "Status", aby zobaczyć wszystkie oferty`,
    onboarding_page2_text3: `Znajdź swoją ofertę i stuknij, aby otworzyć szczegóły`,
    onboarding_page3_text1: `Znajdź ofertę i stuknij "Zgłoś problem"`,
    onboarding_page3_text2: `Wypełnij formularz i zgłoś swój problem`,
    onboarding_page3_text3: `Nasz zespół wsparcia skontaktuje się z tobą!`,
    reengagement_earn_up: `Zarób do <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `Masz jeszcze oferty w trakcie realizacji! <1></1> Nie jest za późno, aby je zakończyć`,
    reengagement_status_text: `Kontynuuj grę`,
    reengagement_expires1: `Wygasa za {{ days }} dni`,
    reengagement_expires2: `Wygasa za {{ hours }} godzin`,
    reengagement_button_text: `ZOBACZ WSZYSTKIE OFERTY`,
    modal_reward: `Łączna liczba nagród`,
    modal_task: `Łączna liczba zadań`,
    modal_time: `Dni do zakończenia`,
    modal_tasks_completed: `Zadania ukończone`,
    modal_remaining_days: `Dni pozostałe`,
    modal_tasks_text: `Zadania`,
    modal_instructions_text: `Instrukcje`,
    complete_offer: `Zakończ Ofertę`,
    report_button_text: `Masz problemy? Kliknij tutaj`,
    report_timestamp: `Będziesz w stanie zgłosić tę ofertę w ciągu {{ reportTime }}`,
    menu_user_support_guide: `Przewodnik wsparcia użytkownika`,
    banner_support: `Wsparcie`,
    mr_text_earned: `Zarobione`,
    mr_text_total: `Łącznie`,
    earn_up_to: `Zarobisz do {{ currencyValue }} {{ currency }}`,
    earn_text: `Zarobione {{ currencyValue }} {{ currency }}`,
    up_to_text: `Do`,
    tab_1_label: `Dobrze płatne Oferty`,
    tab_2_label: `Łatwe Oferty`,
  },
}

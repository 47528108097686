export const pt_BR = {
  translations: {
    action_complete_q_action: `Complete uma ação rápida`,
    action_complete_survey: `Complete uma pesquisa rápida`,
    action_social_media: `Engajamento nas redes sociais`,
    action_simple_registration: `Realize um registro simples`,
    action_discover_new: `Descubra um novo serviço/produto`,
    action_mobile_sub: `Obtenha uma assinatura especial para celular`,
    action_purchase: `Realize uma compra`,
    action_new_service: `Experimente um novo serviço/produto`,
    action_complete_action: `Complete uma ação`,
    action_quick_quiz: `Responda a um quiz rápido`,
    action_donation: `Faça uma doação`,
    tags_free: `Grátis`,
    tags_limited_offer: `Oferta limitada`,
    tags_multi_reward: `Multi-recompensa`,
    tags_top_offer: `Melhor oferta!`,
    tags_new_users: `Apenas para novos usuários`,
    up_to: `Até +{{ currencyValue }} {{ currency }}`,
    menu_faq: `FAQ`,
    menu_offers: `Ofertas`,
    menu_status: `Estado`,
    status_pending: `Pendente`,
    status_reported: `Relatado`,
    status_expired: `Expirado`,
    status_rejected: `Rejeitado`,
    status_completed: `Tarefa concluída`,
    dtUserId: `Seu ID de usuário Digital Turbine é <1>{{ digitalTurbineId }}</1>`,
    complete_text: `Concluir`,
    check_inbox: `Esta oferta foi relatada. Por favor, verifique seu e-mail para obter resposta.`,
    contacted: `Obrigado por entrar em contato com nossa equipe de atendimento ao usuário. Entraremos em contato com você o mais breve possível`,
    description_report: `<0>Obrigado por entrar em contato com a equipe de atendimento ao usuário. Existem duas razões comuns para problemas ao receber {{ currencyText }}:</0><1>1. Requisitos da oferta não atendidos</1><2>Os aplicativos precisam ser baixados e iniciados para concluir a oferta.</2><3>Todos os dados precisam ser inseridos corretamente e com veracidade.</3><4>Muitas vezes, é necessário validar seu endereço de e-mail por meio de um link enviado para sua caixa de entrada.</4><5>Muitos parceiros de publicidade permitem apenas uma transação por pessoa ou residência.</5><6>2. Razões técnicas</6><7>Você não tinha uma conexão de dados funcionando quando iniciou um aplicativo promovido.</7><8>Você já tinha o aplicativo promovido instalado antes de começar a oferta.</8>`,
    problem_report: `Relatório de problema para {{ appName }}`,
    problem_type1: `Nenhum crédito recebido`,
    problem_type2: `Erro durante a oferta`,
    problem_type3: `Outro`,
    email_confirmation: `Confirme seu E-mail`,
    full_name: `Nome completo`,
    message: `Mensagem`,
    submit_text: `Enviar`,
    onboarding_title_1: `Complete a oferta e receba prémios`,
    onboarding_title_2: `Verifique o estado da oferta`,
    onboarding_title_3: `Estão faltando recompensas?`,
    offer_instructions: `Instruções da oferta`,
    offer_details: `*Algumas recompensas podem demorar alguns dias para aparecer na conta`,
    next_text: `Próximo`,
    done_text: `Concluído`,
    onboarding_page2_text1: `Abra o menu localizado no canto superior esquerdo`,
    onboarding_page2_text2: `Toque em "Estado" para ver todas as suas ofertas participadas`,
    onboarding_page2_text3: `Encontre a sua oferta e toque para abrir os detalhes`,
    onboarding_page3_text1: `Encontre a oferta e toque em "Relatar Problema"`,
    onboarding_page3_text2: `Preencha o formulário e relate o seu problema`,
    onboarding_page3_text3: `Nossa equipe de assistência entrará em contato com você!`,
    reengagement_earn_up: `Ganhe até <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `Você ainda tem ofertas em andamento! <1></1> Não é tarde demais para concluí-las`,
    reengagement_status_text: `Continue jogando`,
    reengagement_expires1: `Expira em {{ days }} dias`,
    reengagement_expires2: `Expira em {{ hours }} horas`,
    reengagement_button_text: `VER TODAS AS OFERTAS`,
    modal_reward: `Recompensas Totais`,
    modal_task: `Tarefas Totais`,
    modal_time: `Dias para Concluir`,
    modal_tasks_completed: `Tarefas Concluídas`,
    modal_remaining_days: `Dias Restantes`,
    modal_tasks_text: `Tarefas`,
    modal_instructions_text: `Instruções`,
    complete_offer: `Concluir Oferta`,
    report_button_text: `Enfrentando problemas? Clique aqui`,
    report_timestamp: `Você poderá relatar esta oferta em {{ reportTime }}`,
    menu_user_support_guide: `Guia de Assistência ao Usuário`,
    banner_support: `Assistência`,
    mr_text_earned: `Ganho`,
    mr_text_total: `Total`,
    earn_up_to: `Ganhe até {{ currencyValue }} {{ currency }}`,
    earn_text: `Ganhe {{ currencyValue }} {{ currency }}`,
    up_to_text: `Até`,
    tab_1_label: `Grandes Ofertas`,
    tab_2_label: `Ofertas Fáceis`,
  },
}

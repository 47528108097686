export const sv = {
  translations: {
    action_complete_q_action: `Fullfölj en snabb åtgärd`,
    action_complete_survey: `Fullfölj en snabb undersökning`,
    action_social_media: `Engagemang i sociala medier`,
    action_simple_registration: `Gör en enkel registrering`,
    action_discover_new: `Upptäck en ny tjänst/produkt`,
    action_mobile_sub: `Skaffa en särskild mobilprenumeration`,
    action_purchase: `Gör en köp`,
    action_new_service: `Prova en ny tjänst/produkt`,
    action_complete_action: `Fullfölj en åtgärd`,
    action_quick_quiz: `Svara på en snabb quiz`,
    action_donation: `Gör en donation`,
    tags_free: `Gratis`,
    tags_limited_offer: `Begränsat erbjudande`,
    tags_multi_reward: `Multi Belöning`,
    tags_top_offer: `Top Erbjudande!`,
    tags_new_users: `Endast nya användare`,
    up_to: `Upp till +{{ currencyValue }} {{ currency }}`,
    menu_faq: `FAQ`,
    menu_offers: `Erbjudanden`,
    menu_status: `Status`,
    status_pending: `I avvaktan`,
    status_reported: `Rapporterad`,
    status_expired: `Utgånget`,
    status_rejected: `Avvisad`,
    status_completed: `Avslutad uppgift`,
    dtUserId: `Ditt Digital Turbine-användar-ID är <1>{{ digitalTurbineId }}</1>`,
    complete_text: `Fullbordad`,
    check_inbox: `Detta erbjudande har rapporterats. Vänligen kontrollera din e-post för svaret.`,
    contacted: `Tack för att du kontaktade vår kundvård. Vi återkommer till dig så snart som möjligt`,
    description_report: `<0>Tack för att du kontaktade användarsupportteamet. Det finns två vanliga orsaker till problem med att ta emot {{ currencyText }}</0><1>1. Erbjudandekraven uppfyllda</1><2>Appar måste laddas ner OCH startas för att slutföra erbjudandet.</2><3>Alla data måste fyllas i korrekt och sanningsenligt.</3><4>Ofta måste du validera din e-postadress genom en länk som skickats till din e-post.</4><5>Många reklampartners tillåter endast en transaktion per person eller hushåll.</5><6>2. Tekniska orsaker</6><7>Du hade ingen fungerande dataanslutning när du startade en främjad app.</7><8>Du hade redan den främjade appen installerad innan du började erbjudandet.</8>`,
    problem_report: `Problemrapport för {{ appName }}`,
    problem_type1: `Inga krediter mottagna`,
    problem_type2: `Fel under erbjudandet`,
    problem_type3: `Annat`,
    email_confirmation: `Bekräfta din e-post`,
    full_name: `Fullständigt namn`,
    message: `Meddelande`,
    submit_text: `Skicka`,
    onboarding_title_1: `Fullborda erbjudandet och få belöningar`,
    onboarding_title_2: `Kontrollera erbjudandestatus`,
    onboarding_title_3: `Saknade belöningar?`,
    offer_instructions: `Erbjudandeinstruktioner`,
    offer_details: `*Vissa belöningar kan ta några dagar innan de syns på kontot`,
    next_text: `Nästa`,
    done_text: `Klart`,
    onboarding_page2_text1: `Öppna menyn som är placerad i det övre vänstra hörnet`,
    onboarding_page2_text2: `Tryck på "Status" för att se alla dina erbjudanden`,
    onboarding_page2_text3: `Hitta ditt erbjudande och tryck för att öppna detaljer`,
    onboarding_page3_text1: `Hitta erbjudandet och tryck på "Rapportera problem"`,
    onboarding_page3_text2: `Fyll i formuläret och skicka in ditt problem`,
    onboarding_page3_text3: `Vårt supportteam kommer att återkomma till dig!`,
    reengagement_earn_up: `Tjäna upp till <1>+{{ currencyValue }}</1> {{ currencyName }}`,
    reengagement_description: `Du har fortfarande erbjudanden som är pågående! <1></1> Det är inte för sent att slutföra dem`,
    reengagement_status_text: `Fortsätt spela`,
    reengagement_expires1: `Upphör om {{ days }} dagar`,
    reengagement_expires2: `Upphör om {{ hours }} timmar`,
    reengagement_button_text: `SE ALLA ERBJUDANDEN`,
    modal_reward: `Totalt antal belöningar`,
    modal_task: `Totalt antal uppgifter`,
    modal_time: `Dagar att slutföra`,
    modal_tasks_completed: `Slutförda uppgifter`,
    modal_remaining_days: `Dagar kvar`,
    modal_tasks_text: `Uppgifter`,
    modal_instructions_text: `Instruktioner`,
    complete_offer: `Slutför erbjudandet`,
    report_button_text: `Har du problem? Klicka här`,
    report_timestamp: `Du kommer att kunna rapportera detta erbjudande om {{ reportTime }}`,
    menu_user_support_guide: `Användarstödjande guide`,
    banner_support: `Stöd`,
    mr_text_earned: `Förtjänat`,
    mr_text_total: `Totalt`,
    earn_up_to: `Förtjäna upp till {{ currencyValue }} {{ currency }}`,
    earn_text: `Förtjäna {{ currencyValue }} {{ currency }}`,
    up_to_text: `Upp till`,
    tab_1_label: `Högavlönade erbjudanden`,
    tab_2_label: `Lätta erbjudanden`,
  },
}
